import { useDrag } from "@use-gesture/react";

import React, { memo, useCallback, useRef, useState } from "react";

import RecordIcon from "astrid-components/lib/components/Assets/Icons/Record";
import RotateIcon from "astrid-components/lib/components/Assets/Icons/Rotate";
import Timeline from "astrid-components/lib/components/Audio/Timeline";
import clamp from "astrid-helpers/src/clamp";
import lerp from "astrid-helpers/src/lerp";

import useCanRecord from "../../hooks/useCanRecord";
import usePrerolling from "../../hooks/usePrerolling";
import { pauseRecorder, retakeRecording } from "../../services/recorder";
import { getActionType, useActionType } from "../../state/action";
import { getAutomation } from "../../state/automation";
import { setTool } from "../../state/tool";

import RecordTool from "../Tools/Record";

import Button from "./Button";

function Record() {
	const action = useActionType();
	const canRecord = useCanRecord();
	const prerolling = usePrerolling();

	const prevAction = useRef();
	const [move, setMove] = useState(0);

	const onDrag = useDrag(
		useCallback(
			({ tap, last, first, event, movement }) => {
				event.stopPropagation();

				if (first) {
					prevAction.current = getActionType();

					if (prevAction.current === "record") {
						pauseRecorder();
					}
				} else if (tap || last) {
					setMove(0);

					if (
						tap &&
						(!getAutomation() || Timeline.getDuration() < 10000) &&
						prevAction.current !== "record"
					) {
						// startRecording();
					} else if (move === 1) {
						retakeRecording();
					}
				} else {
					const move = Math.round(clamp(-movement[1] / 30, -1, 1));

					if (move < 0) {
						setTool(() => RecordTool);
					} else {
						setTool();
					}

					setMove(move);
				}
			},
			[move],
		),
	);

	return (
		<Button
			color="negative"
			disabled={!canRecord}
			active={action === "record"}
			style={{ overflow: "hidden", whiteSpace: "initial" }}
			{...onDrag()}
		>
			<div
				style={{
					width: 22,
					paddingTop: 6,
					alignSelf: "flex-start",
					transition: "transform 0.2s ease-in-out",
					transform: `translateY(${Math.round(lerp(0, -42, move))}px)`,
				}}
			>
				<RecordIcon animation={prerolling && "blink"} />
				<RotateIcon style={{ transform: `scaleX(-1)` }} />
			</div>
		</Button>
	);
}

export default memo(Record);
