import { useDrag } from "@use-gesture/react";
import { saveAs } from "file-saver";

import { useCallback, useEffect, useState } from "react";

import Download from "astrid-components/lib/components/Assets/Icons/Download";
import Pdf from "astrid-components/lib/components/Assets/Pdf";
import Timeline from "astrid-components/lib/components/Audio/Timeline";
import List from "astrid-components/lib/components/Data/List";
import Button from "astrid-components/lib/components/Inputs/Button";
import Flex from "astrid-components/lib/components/Layout/Flex";
import MouseLabel from "astrid-components/lib/components/Modules/MouseLabel";
import Text from "astrid-components/lib/components/Text/Text";
import map from "astrid-helpers/src/map";
import msToTime from "astrid-helpers/src/msToTime";
import { push } from "astrid-hooks/src/useHistory";

import * as firebase from "../../helpers/firebase";
import * as master from "../../helpers/master";
import nudgeTimeline from "../../helpers/nudgeTimeline";
import useText from "../../hooks/useText";
import { jumpToPosition } from "../../services/timeline";
import { clips } from "../../state/clips";
import { getTrack } from "../../state/track";

const FlexText = ({ label, children }) => (
	<Flex justifyContent="flex-start" minHeight="22px" fontSize={13}>
		<Text flexShrink="0" width="90px">
			{label}:
		</Text>
		<Text lineHeight={1} color="default">
			{children}
		</Text>
	</Flex>
);

function AudioFile({ file, onClick, selected }) {
	const t = useText();
	const size = Timeline.useSize();
	const rect = Timeline.useRect();
	const offset = Timeline.useOffset();

	const [clip] = clips.byNumber(file.number);

	const [dragging, setDragging] = useState(false);

	const { bitDepth, channels, sampleRate, web, created, studio, length, createdBy, url } = file.data;

	const createdAt = created && new Date(created.seconds * 1000).toLocaleString();

	const download = (e) => {
		e.stopPropagation();

		saveAs(url, file.id);
	};

	useEffect(() => {
		if (dragging) {
			MouseLabel.setLabel(file.id);
		} else {
			MouseLabel.setLabel();
		}
	}, [dragging, file.id]);

	const onDrag = useDrag(
		useCallback(
			({ tap, first, last, xy }) => {
				if (tap) {
					onClick();

					clip && jumpToPosition(clip.position);
				} else if (!first) {
					setDragging(true);

					if (last) {
						setDragging(false);

						const x = xy[0] - rect.left + window.pageXOffset;
						const y = xy[1] - rect.top + window.pageYOffset;

						if (y >= 0 && y <= rect.height && x >= 0 && x <= rect.width) {
							const position = offset + map(x, 0, rect.width, 0, size);
							const clipAtPosition = clips.atPosition(position, null, getTrack());

							if (!clipAtPosition) {
								const clip = clips.create(file.id, {
									start: 0,
									end: file.length,
									track: getTrack(),
									pageIn: Pdf.getPage() || 1,
									pageOut: Pdf.getPage() || 1,
									position: position,
								});

								push(
									t("addClip", "Add clip"),
									firebase.commit([
										clip,
										nudgeTimeline(position + 1, file.length),
										master.modify(position),
									]),
								);
							}
						}
					}
				}
			},
			[file, offset, onClick, rect, size, t],
		),
	);

	return (
		<List.Item active={selected} disabled={!clip}>
			<Flex cursor={dragging ? "grabbing" : "pointer"} height={45} padding="0 10px 0 20px" {...onDrag()}>
				<Text fontWeight={600}>{file.id}</Text>
				<Flex gap="6px">
					<Text color="default" fontSize={13}>
						{msToTime(length, false)}
					</Text>
					<Button transparent size="small" onClick={download} alignItems="center">
						<Download size={16} />
					</Button>
				</Flex>
			</Flex>
			{selected && (
				<Flex padding="0 10px 10px 20px" alignItems="stretch" justifyContent="start" gap="10px">
					<div>
						{created && <FlexText label={t("created", "Created")}>{createdAt}</FlexText>}
						{createdBy && <FlexText label={t("createdBy", "Created by")}>{createdBy.email}</FlexText>}
						{studio && <FlexText label={t("studio", "Studio")}>{studio}</FlexText>}
						{bitDepth && <FlexText label={t("bitDepth", "Bit depth")}>{bitDepth}</FlexText>}
						{channels && <FlexText label={t("channels", "Channels")}>{channels}</FlexText>}
						{sampleRate && <FlexText label={t("sampleRate", "Sample rate")}>{sampleRate}</FlexText>}
						{web && <FlexText label={t("web", "Web")}>{web}</FlexText>}
					</div>
				</Flex>
			)}
		</List.Item>
	);
}

export default AudioFile;
