import { useDrag } from "@use-gesture/react";

import React, { memo, useCallback, useEffect, useRef, useState } from "react";

import BookmarkIcon from "astrid-components/lib/components/Assets/Icons/Bookmark";
import PauseIcon from "astrid-components/lib/components/Assets/Icons/Pause";
import PlayIcon from "astrid-components/lib/components/Assets/Icons/Play";
import RecordIcon from "astrid-components/lib/components/Assets/Icons/Record";
import Pdf from "astrid-components/lib/components/Assets/Pdf";
import List from "astrid-components/lib/components/Data/List";
import Button from "astrid-components/lib/components/Inputs/Button";
import Flex from "astrid-components/lib/components/Layout/Flex";
import Swipe from "astrid-components/lib/components/Modules/Swipe";
import Text from "astrid-components/lib/components/Text/Text";
import msToTime from "astrid-helpers/src/msToTime";
import { push } from "astrid-hooks/src/useHistory";

import cutClips from "../../helpers/cutClips";
import * as firebase from "../../helpers/firebase";
import jumpToMarker from "../../helpers/jumpToMarker";
import selectMarker from "../../helpers/selectMarker";
import useCanRecord from "../../hooks/useCanRecord";
import useClosestSilence from "../../hooks/useClosestSilence";
import usePrerolling from "../../hooks/usePrerolling";
import useSelectionRange from "../../hooks/useSelectionRange";
import { startEdit, startRecorder, stopRecorder } from "../../services/recorder";
import { useActionType } from "../../state/action";
import { useEditor, useProofer, useRecorder } from "../../state/permissions";
import { useSelectedMarker } from "../../state/selectedMarker";

import Content from "./Common/Content";
import PageButton from "./Common/PageButton";
import Edit from "./Edit";

const pauses = {
	paragraph: 3000,
	chapter: 5000,
};

function Record({ marker }) {
	const action = useActionType();
	const canRecord = useCanRecord();
	const prerolling = usePrerolling();
	const [range, detections, index] = useSelectionRange(marker.position, marker.expand);

	const state = useRef();

	const [playing, setPlaying] = useState();
	const [recording, setRecording] = useState();

	const onDrag = useDrag(
		useCallback(
			async ({ tap, args, event }) => {
				event.stopPropagation();

				if (tap) {
					if (playing || recording) {
						stopRecorder();
					} else if (args[0] === "play") {
						setPlaying(true);
						const position = detections[index][1] - 100;
						await startRecorder({ position, length: range[1] - position });
						setPlaying(false);
					} else {
						setRecording(true);
						const useRange = state.current ? state.current[0] : range;
						state.current && state.current[1]();
						state.current = [useRange, await startEdit(useRange)];
						setRecording(false);
					}
				}
			},
			[detections, index, playing, range, recording],
		),
	);

	useEffect(() => {
		if (marker.expand) {
			state.current = undefined;
		}
	}, [marker.expand]);

	if (!range) return false;

	return (
		<>
			<Button transparent size="tiny" color="positive" active={playing || recording} {...onDrag("play")}>
				{playing || recording ? <PauseIcon size={14} /> : <PlayIcon size={14} />}
			</Button>
			{marker.staged && canRecord && (
				<Button
					transparent
					size="tiny"
					color="negative"
					active={recording && action === "record"}
					{...onDrag("record")}
				>
					<RecordIcon size={14} animation={recording && prerolling && "blink"} />
				</Button>
			)}
		</>
	);
}

function Pause({ marker }) {
	const range = useClosestSilence(marker.position);

	const onClick = useCallback(
		(e) => {
			e.stopPropagation();

			const updates = cutClips({
				range,
				fadein: 50,
				fadeout: 100,
				select: true,
				overflow: true,
				transaction: true,
				extend: pauses[marker.type] - (range[1] - range[0]),
			});

			marker.check();
			updates.push(marker);

			push("Lägg till paus", firebase.commit(updates));

			selectMarker();
		},
		[marker, range],
	);

	if (!range) return false;

	return (
		<Button transparent size="tiny" onClick={onClick}>
			<BookmarkIcon size={14} />
		</Button>
	);
}

function Marker({ marker, ...props }) {
	const ref = useRef();
	const swipeRef = useRef();
	const editor = useEditor();
	const proofer = useProofer();
	const recorder = useRecorder();
	const selectedMarker = useSelectedMarker();

	const selected = selectedMarker && selectedMarker[0] === marker.id;

	const onClick = useCallback(() => {
		Pdf.setSearchIndex();

		selectMarker(!selected && marker.id);
		jumpToMarker(marker.id);
	}, [marker.id, selected]);

	const onSwipe = useCallback(
		(value) => {
			let returnValue = marker.swipe;

			if (value > 30) {
				returnValue = marker.stage();
				firebase.commit(marker);
			} else if (value < -30) {
				if (!marker.checked) {
					selectMarker();
				}

				returnValue = marker.check();
				const undo = firebase.commit(marker);

				if (!marker.checked) {
					push("Dimma marker", undo);
				}
			}

			return returnValue;
		},
		[marker],
	);

	const right = !marker.checked && marker.showRecord && (editor || (recorder && marker.staged));
	const left = !marker.staged && recorder;

	return (
		<List.Item ref={ref} disabled={marker.checked} {...props}>
			<Swipe
				height={60}
				left={left}
				right={right}
				x={marker.swipe}
				swipeTarget={swipeRef}
				onChange={onSwipe}
				onClick={!marker.checked && onClick}
			>
				<List.Item first active={selected} color={marker.color}>
					<Flex cursor="pointer" minHeight={60} padding={10} ref={swipeRef}>
						<PageButton color={marker.color}>{marker.page}</PageButton>

						<Content color={marker.color} label={marker.label} style={{ marginLeft: 10 }}>
							{marker.short && <Text fontWeight={600}>{marker.short}</Text>}
						</Content>

						<Flex marginLeft="auto">
							{proofer &&
								(recorder && selected && (marker.showRecord || marker.showPause) ? (
									<Flex>
										{marker.showRecord && <Record marker={marker} />}
										{marker.showPause && <Pause marker={marker} />}
									</Flex>
								) : (
									<Button transparent size="tiny" textSize="small" color={marker.color}>
										{msToTime(marker.position, false)}
									</Button>
								))}
						</Flex>
					</Flex>

					{proofer && selected && <Edit marker={marker} />}
				</List.Item>
			</Swipe>
		</List.Item>
	);
}

export default memo(Marker);
